/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chat': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.4 13.8V2.6H2.6v9.6h11.2l1.6 1.6zm1.677 3.385A.2.2 0 0017.2 17V2.6A1.8 1.8 0 0015.4.8H2.6A1.8 1.8 0 00.8 2.6v9.6A1.8 1.8 0 002.6 14h10.786a.8.8 0 01.566.234l2.907 2.907a.2.2 0 00.218.044z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7 7.5a1 1 0 11-2 0 1 1 0 012 0zm3 0a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2z" _fill="#fff"/>'
  }
})
